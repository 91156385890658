<script>
import Mapbox from 'mapbox-gl';
export default {
  name: "LocationPicker",
  emits: ['changeMarker'],
  props: {
    modelValue: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    modelValue: {
      handler (newValue, oldValue) {
        console.log('watch modelValue')
        console.log(newValue)
        console.log(newValue)
        if (oldValue && oldValue.length && newValue && newValue.length && newValue[0] !== oldValue[0] && newValue[1] !== oldValue[1]) {
          this.updateMarker(newValue)
        } else if (newValue && newValue.length && !oldValue) {
          this.updateMarker(newValue)
        }
      },
      deep: true
    }
  },
  data () {
    return {
      mapReady: false,
      mapbox: null,
      marker: null
    }
  },
  methods: {
    updateMarker (latlng) {
      console.log('update marker with latlng:', latlng)
      if (this.marker) {
        this.marker.remove()
      }
      const mapboxCoords = [latlng[1], latlng[0]];
      this.marker = new Mapbox.Marker({
        color: "#fc2249",
        draggable: true
      }).setLngLat(mapboxCoords)
          .addTo(this.mapbox);
      this.mapbox.setCenter(mapboxCoords);
      this.marker.on('dragend', () => {
        const lngLat = this.marker.getLngLat();
        this.$emit('update:modelValue', [lngLat.lat, lngLat.lng]);
        this.$emit('changeMarker', [lngLat.lat, lngLat.lng])
      });
    }
  },
  mounted () {
    let mapConfig = {
      container: this.$refs.mapboxGl,
      style: 'mapbox://styles/mapbox/streets-v9',
      zoom: 11,
      minZoom: 6,
      maxZoom: 200,
      scrollZoom: true
    }
    if (this.modelValue.length > 0) {
      mapConfig.center = [this.modelValue[1], this.modelValue[0]]
    } else {
      mapConfig.center = [2.3522219, 48.856614]
    }
    this.mapbox = new Mapbox.Map(mapConfig);
    this.mapbox.on('load', () => {
      this.mapReady = true;
      if (this.modelValue.length) {
        this.updateMarker(this.modelValue);
      }
    });
    this.mapbox.on('style.load', () => {
      if (this.mapbox.getLayer('landcover-outdoors') && this.mapbox.getSource('composite')) {
      } else {
        console.warn('La couche ou la source spécifiée n\'existe pas');
      }
    });
    this.mapbox.on('click', (e) => {
      let latitude = e.lngLat.lat;
      let longitude = e.lngLat.lng;
      this.$emit('update:modelValue', [latitude, longitude]);
      this.$emit('changeMarker', [latitude, longitude]);
      this.updateMarker([latitude, longitude]);
    });
    this.mapbox.on('mouseover', () => {
      this.mapbox.getCanvas().style.cursor = 'pointer';
    });
  }
}
</script>

<template>
  <div id="map" ref="mapboxGl" v-bind="$attrs"></div>
</template>

<style scoped>
#map {
  cursor: pointer;
}
</style>